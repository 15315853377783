<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-news' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to All Articles</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Categories</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Categories"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.categoryDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Category</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed light v-on="on">
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Categories</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="categories"
            no-data-text="No Categories found"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.categoryDialog.open(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <category-dialog ref="categoryDialog" />
  </div>
</template>

<script>
import CategoryDialog from "../components/CategoryDialog";

export default {
  components: {
    CategoryDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "News",
          disabled: false,
          to: { name: "module-craigtoun-news" },
          exact: true,
        },
        {
          text: "Categories",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Slug", value: "slug" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      deleteCategory: {
        dialog: false,
        category: {},
        loading: false,
      },
    };
  },

  computed: {
    categories() {
      let categories = this.$store.getters["craigtoun/newsStore/categories"];

      if (this.searchTerm !== "") {
        categories = categories.filter(c => {
          const name = c.name.toLowerCase();
          const slug = c.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || slug.inclues(searchTerm);
        });
      }

      return categories;
    },
  },
};
</script>
